<template>
  <div class="contact-us">
    <div class="content">
      <div
        class="contact-us-img"
        :style="{ background: `url(${publicPath}${content.image}.jpg)` }"
      ></div>
      <div class="text">
        <h1>{{ content.text }}</h1>
        <router-link :to="{ path: '/contact' }">Contáctanos</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  props: {
    content: {
      type: Object,
      required: true,
      default: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-us {
  height: 800px;
  background-color: #222ac3;
  .content {
    display: flex;
    align-items: center;
    color: white;
    height: 100%;
    @media (max-width: 1280px) {
      flex-direction: column;
    }
    .contact-us-img {
      width: 50%;
      height: 100%;
      @media (max-width: 1280px) {
        width: 100%;
      }
    }
    .text {
      @media (max-width: 1280px) {
        padding: 60px;
        height: 100%;
        text-align: left;
        width: 100%;
      }
      width: 50%;
      padding: 0 140px;
      h1 {
        font-weight: 100;
        font-size: 45px;
      }
      a {
        border-radius: 5px;
        background-color: #0eebe1;
      }
    }
  }
}
</style>
