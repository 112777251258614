<template>
  <div class="courses">
    <PageBanner
      :firstword="coursesContent[$route.params.id].firstword"
      :keyword="coursesContent[$route.params.id].keyword"
      :secondword="coursesContent[$route.params.id].secondword"
      :img="'courses.jpg'"
      :subtext="coursesContent[$route.params.id].subtitle"
    />
    <PageDetail
      :img="'courses-detail.svg'"
      :title="coursesContent[$route.params.id].titleDescription"
      :subtitle="coursesContent[$route.params.id].description"
    />
    <div class="caracteristics">
      <h1>Características.</h1>
      <swiper
        :slides-per-view="3"
        :space-between="1"
        :navigation="{ nextEl: '.next-slide', prevEl: '.prev-slide' }"
        class="swiper-courses"
      >
        <swiper-slide
          class="swiper-slide"
          v-for="(course, $c) in coursesContent[$route.params.id].caracteristics"
          :key="$c"
        >
          <div class="courses-cards">
            <div @click="openModal(), setDescription($c)" class="cards">
              <img src="../assets/img/course.svg" alt="" />
              <div class="content">
                <span class="information">i</span>
                <div class="title">
                  <h1>{{ course.caracteristicTitle }}</h1>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="navigation">
          <button class="prev-slide"><i class="flaticon-up-arrow"></i></button>
          <button class="next-slide"><i class="flaticon-up-arrow"></i></button>
        </div>
      </swiper>
    </div>
    <ContactUsFooter :content="{ text: 'Pregunta por nuestros cursos', image: 'CTA-JAMA-1' }" />
    <Modal :description="coursesContent[$route.params.id].caracteristics[selected].description" :value="modal" @close="openModal" />
  </div>
</template>

<script>
import store from "../store";
import Modal from "@/components/modal.vue";
import ContactUsFooter from "@/components/contactus-footer.vue";
import PageBanner from "@/components/page-banners.vue";
import PageDetail from "@/components/page-detail.vue";
//Import swiper core
import SwiperCore, { Navigation } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation]);
export default {
  data() {
    return {
      modal: false,
      selected: 0,
    };
  },
  components: {
    PageBanner,
    PageDetail,
    Swiper,
    SwiperSlide,
    ContactUsFooter,
    Modal,
  },
  methods: {
    openModal() {
      this.modal = !this.modal;
    },
    setDescription(id){
      this.selected = id
    }
  },
  computed: {
    coursesContent() {
      return this.$store.state.courses;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (
      Object.prototype.hasOwnProperty.call(store.state.courses, to.params.id)
    ) {
      next();
    } else {
      next("/error404");
    }
    next();
  },
  beforeRouteUpdate(to, from, next) {
    if (
      Object.prototype.hasOwnProperty.call(store.state.courses, to.params.id)
    ) {
      next();
    } else {
      next("/error404");
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.courses {
  .caracteristics {
    padding: 140px;
    background-color: #f9f9ff;
    h1 {
      margin: 0 0 124px 0;
    }
    .swiper-courses {
      position: relative;
      height: 350px;
      .swiper-slide {
        .courses-cards {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 100%;
          .cards {
            height: 150px;
            background-color: #222ac3;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding: 0 40px;
            position: relative;
            cursor: pointer;
            .content {
              width: 175px;
              margin-left: 35px;
              .information {
                position: absolute;
                border: 1px solid white;
                border-radius: 100%;
                padding: 0 10px;
                right: 10px;
                top: 10px;
              }
              h1 {
                color: #0eebe1;
                font-size: 24px;
                margin: 0 0 10px 0;
                line-height: 35px;
              }
              span {
                color: white;
                font-size: 15px;
                font-weight: 600;
              }
            }
          }
        }
      }
      .navigation {
        position: absolute;
        bottom: 0px;
        left: 40%;
        z-index: 1;
        button {
          width: 125px;
          height: 50px;
          border-radius: 25px;
          margin: 0 15px;
          background-color: #222ac3;
          color: white;
          position: relative;
          border: none;
          i {
            right: 50px;
            position: absolute;
            top: 13px;
            &::before {
              margin: 0;
              font-size: 24px;
            }
          }
          &.prev-slide {
            i {
              transform: rotate(270deg);
            }
          }
          &.next-slide {
            i {
              transform: rotate(450deg);
            }
          }
          &.swiper-button-disabled {
            background-color: #a6a7c1;
            border: 1px solid #a6a7c1;
          }
        }
      }
    }
  }
}
</style>
