<template>
  <div class="page-detail">
    <img :src="`${publicPath}${img}`" alt="" />
    <div class="text">
      <h4>{{ title }}</h4>
      <p>
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  props: {
    img: {
      type: String,
      required: true,
      default: "",
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    subtitle: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  width: 1280px;
  margin: auto;
  @media (max-width: 1280px) {
    width: 100%;
    flex-direction: column;
    img {
      width: 50%;
    }
  }
  img {
    width: 625px;
    @media (max-width: 1280px) {
          width: 100%;
        }
  }
  .text {
    margin-left: 100px;
    @media (max-width: 1280px) {
      margin: 0;
      p{
        width: 100%;
      }
    }
    h4 {
      font-size: 45px;
      color: #000033;
      margin: 0 0 30px 0;
      @media (max-width: 1280px) {
          font-size: 30px;
        }
    }
    p {
      color: #676777;
    }
  }
}
</style>
