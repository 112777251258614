<template>
  <div :style="{ background: `url(${publicPath}${img})` }" class="page-banner">
    <div class="content">
      <h1>
        {{ firstword }} <span>{{ keyword }}</span> {{ secondword }}
      </h1>
      <h4>
        {{ subtext }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  props: {
    img: {
      type: String,
      required: true,
      default: "",
    },
    keyword: {
      type: String,
      required: true,
      default: "",
    },
    firstword: {
      type: String,
      required: true,
      default: "",
    },
    secondword: {
      type: String,
      required: true,
      default: "",
    },
    subtext: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-banner {
  height: 690px;
  display: flex;
  justify-content: center;
  .content {
    width: 1280px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1280px) {
      width: 100%;
      padding: 20px;
    }
    h1,
    h5 {
      margin: 0;
      width: 1080px;
      @media (max-width: 1280px) {
        width: 100%;
      }
    }
    h1 {
      font-size: 80px;
      line-height: 86px;
      @media (max-width: 1280px) {
        line-height: 1;
      }
      span {
        color: #0eebe1;
      }
      @media (max-width: 1280px) {
        font-size: 40px;
      }
    }
    h5 {
      font-size: 20px;
      font-weight: 100;
      line-height: 30px;
    }
  }
}
</style>
